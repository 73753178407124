import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48')
];

export const server_loads = [];

export const dictionary = {
		"/": [~2],
		"/about-us": [~4],
		"/activity/[slug]": [~5],
		"/ask-expert": [~6],
		"/become-a-host": [~7],
		"/booking-confirmation": [~8],
		"/calendar-support": [~9],
		"/careers": [~10],
		"/careers/[slug]": [~11],
		"/careers/[slug]/apply": [~12],
		"/company/[slug]": [~13],
		"/connect/[slug]": [14],
		"/expert-advice": [~15],
		"/expert-advice/[location]": [~16],
		"/expert-advice/[location]/[slug]": [~17],
		"/expert-requested": [18],
		"/forgot-password": [19],
		"/journal": [~20],
		"/journal/[slug]": [~21],
		"/list-your-space": [~22],
		"/login": [23],
		"/press": [~24],
		"/reset-password": [~25],
		"/scratch": [26],
		"/search": [27],
		"/side-events/[slug]": [~28],
		"/signup": [29],
		"/sitemap": [~30],
		"/sitemap/activities": [~31],
		"/sitemap/spaces": [~32],
		"/spaces/[city]": [~33],
		"/spaces/[city]/[slug]": [~34],
		"/spaces/[city]/[slug]/booking": [~35],
		"/thanks-for-creating-an-account": [36],
		"/thankyou-for-applying": [37],
		"/user-dashboard": [38],
		"/user-dashboard/account-security": [39],
		"/user-dashboard/billing-information": [40],
		"/user-dashboard/calendar": [41],
		"/user-dashboard/edit-profile": [42],
		"/user-dashboard/my-listings": [43],
		"/user-dashboard/wishlist": [44],
		"/venues": [~46],
		"/venues/[city]": [~47],
		"/venues/[city]/[category]": [~48],
		"/v/[slug]": [~45],
		"/[unknown]": [~3]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';