const { VITE_SENTRY_DSN, VITE_LIVE_SITE } = import.meta.env;
import * as SentrySvelte from "@sentry/sveltekit";

import type { HandleClientError } from "@sveltejs/kit"

SentrySvelte.init({
  dsn: VITE_SENTRY_DSN,
  tracesSampleRate: 0.2,
  environment: VITE_LIVE_SITE === 'true' ? 'production' : 'development',
  enabled: VITE_LIVE_SITE === 'true' ? true : false
});
export const handleError: HandleClientError = ({ error, event }) => {
  const errorId = crypto.randomUUID()
  SentrySvelte.captureException(error, {
    contexts: { sveltekit: { event, errorId } }
  })

  return {
    message: 'Error:', errorId
  }

}